const chapters = [
	{ value: 'No Chapter', label: 'No Chapter', logo: 'No Chapter', url: '' },
	{ value: 'Alabama', label: 'Alabama', logo: 'Alabama', url: 'alabama' },
	{ value: 'Alaska', label: 'Alaska', logo: 'Alaska', url: 'alaska' },
	{ value: 'Arizona', label: 'Arizona', logo: 'Arizona', url: 'arizona' },
	{ value: 'Arkansas', label: 'Arkansas', logo: 'Arkansas', url: 'arkansas' },
	{
		value: 'Capital Region New York',
		label: 'Capital Region New York',
		logo: 'Capital_Region_New_York',
		url: 'capitalregionny',
	},
	{
		value: 'Central Florida',
		label: 'Central Florida',
		logo: 'Central_Florida',
		url: 'centralflorida',
	},
	{
		value: 'Central Texas',
		label: 'Central Texas',
		logo: 'Central_Texas',
		url: 'centraltexas',
	},
	{
		value: 'Central Valley California',
		label: 'Central Valley California',
		logo: 'Central_Valley',
		url: 'centralvalley',
	},
	{ value: 'Colorado', label: 'Colorado', logo: 'Colorado', url: 'colorado' },
	{
		value: 'Connecticut',
		label: 'Connecticut',
		logo: 'Connecticut',
		url: 'connecticut',
	},
	{ value: 'Delaware', label: 'Delaware', logo: 'Delaware', url: 'delaware' },
	{
		value: 'Eastern Pennsylvania',
		label: 'Eastern Pennsylvania',
		logo: 'Eastern_Pennsylvania',
		url: 'easternpa',
	},
	{
		value: 'Florida Panhandle',
		label: 'Florida Panhandle',
		logo: 'Florida_Panhandle',
		url: 'panhandle',
	},
	{ value: 'Georgia', label: 'Georgia', logo: 'Georgia', url: 'georgia' },
	{
		value: 'Greater Central New York',
		label: 'Greater Central New York',
		logo: 'Greater_Central_NY',
		url: 'greatercentralny',
	},
	{
		value: 'Greater Kansas',
		label: 'Greater Kansas',
		logo: 'Greater_Kansas',
		url: 'kansas',
	},
	{
		value: 'Greater Los Angeles and Central Coast',
		label: 'Greater Los Angeles and Central Coast',
		logo: 'Greater_Los_Angeles_and_Central_Coast',
		url: 'la',
	},
	{
		value: 'Greater Philadelphia',
		label: 'Greater Philadelphia',
		logo: 'Greater_Philadelphia',
		url: 'philadelphia',
	},
	{
		value: 'Greater Sacramento',
		label: 'Greater Sacramento',
		logo: 'Greater_Sacramento',
		url: 'sacramento',
	},
	{
		value: 'Greater San Francisco Bay Area',
		label: 'Greater San Francisco Bay Area',
		logo: 'Greater_San_Francisco_Bay',
		url: 'sfbayarea',
	},
	{ value: 'Hawaii', label: 'Hawaii', logo: 'Hawaii', url: 'hawaii' },
	{
		value: 'Hudson Valley/Westchester',
		label: 'Hudson Valley/Westchester',
		logo: 'Hudson_Valley_Westchester',
		url: 'hvwestchester',
	},
	{ value: 'Idaho', label: 'Idaho', logo: 'Idaho', url: 'idaho' },
	{ value: 'Illinois', label: 'Illinois', logo: 'Illinois', url: 'illinois' },
	{ value: 'Indiana', label: 'Indiana', logo: 'Indiana', url: 'indiana' },
	{
		value: 'Inland Empire and Desert Cities',
		label: 'Inland Empire and Desert Cities',
		logo: 'Inland_Empire_and_Desert_Cities',
		url: 'inlandempiredc',
	},
	{ value: 'Iowa', label: 'Iowa', logo: 'Iowa', url: 'iowa' },
	{ value: 'Kentucky', label: 'Kentucky', logo: 'Kentucky', url: 'kentucky' },
	{
		value: 'Louisiana',
		label: 'Louisiana',
		logo: 'Louisiana',
		url: 'louisiana',
	},
	{ value: 'Maine', label: 'Maine', logo: 'Maine', url: 'maine' },
	{ value: 'Maryland', label: 'Maryland', logo: 'Maryland', url: 'maryland' },
	{
		value: 'Massachusetts',
		label: 'Massachusetts',
		logo: 'Massachusetts',
		url: 'massachusetts',
	},
	{ value: 'Michigan', label: 'Michigan', logo: 'Michigan', url: 'michigan' },
	{
		value: 'Minnesota',
		label: 'Minnesota',
		logo: 'Minnesota',
		url: 'minnesota',
	},
	{
		value: 'Mississippi',
		label: 'Mississippi',
		logo: 'Mississippi',
		url: 'mississippi',
	},
	{ value: 'Missouri', label: 'Missouri', logo: 'Missouri', url: 'missouri' },
	{ value: 'Montana', label: 'Montana', logo: 'Montana', url: 'montana' },
	{
		value: 'National Capital Area',
		label: 'National Capital Area',
		logo: 'National_Capital_Area',
		url: 'ncac',
	},
	{ value: 'Nebraska', label: 'Nebraska', logo: 'Nebraska', url: 'nebraska' },
	{ value: 'Nevada', label: 'Nevada', logo: 'Nevada', url: 'nevada' },
	{
		value: 'New Hampshire',
		label: 'New Hampshire',
		logo: 'New_Hampshire',
		url: 'newhampshire',
	},
	{
		value: 'New Jersey',
		label: 'New Jersey',
		logo: 'New_Jersey',
		url: 'newjersey',
	},
	{
		value: 'New Mexico',
		label: 'New Mexico',
		logo: 'New_Mexico',
		url: 'newmexico',
	},
	{
		value: 'New York City',
		label: 'New York City',
		logo: 'New_York_City',
		url: 'nyc',
	},
	{
		value: 'New York Long Island',
		label: 'New York Long Island',
		logo: 'New_York_Long_Island',
		url: 'longisland',
	},
	{
		value: 'North Carolina',
		label: 'North Carolina',
		logo: 'North_Carolina',
		url: 'northcarolina',
	},
	{
		value: 'North Dakota',
		label: 'North Dakota',
		logo: 'North_Dakota',
		url: 'northdakota',
	},
	{
		value: 'North Florida',
		label: 'North Florida',
		logo: 'North_Florida',
		url: 'northflorida',
	},
	{
		value: 'North Texas',
		label: 'North Texas',
		logo: 'North_Texas',
		url: 'northtexas',
	},
	{ value: 'Ohio', label: 'Ohio', logo: 'Ohio', url: 'ohio' },
	{ value: 'Oklahoma', label: 'Oklahoma', logo: 'Oklahoma', url: 'oklahoma' },
	{
		value: 'Orange County',
		label: 'Orange County',
		logo: 'Orange_County',
		url: 'orangecounty',
	},
	{ value: 'Oregon', label: 'Oregon', logo: 'Oregon', url: 'oregon' },
	{
		value: 'Puerto Rico',
		label: 'Puerto Rico',
		logo: 'Puerto_Rico',
		url: 'puertorico',
	},
	{
		value: 'Rhode Island',
		label: 'Rhode Island',
		logo: 'Rhode_Island',
		url: 'rhodeisland',
	},
	{
		value: 'San Diego',
		label: 'San Diego',
		logo: 'San_Diego',
		url: 'sandiego',
	},
	{
		value: 'South Carolina',
		label: 'South Carolina',
		logo: 'South_Carolina',
		url: 'southcarolina',
	},
	{
		value: 'South Dakota',
		label: 'South Dakota',
		logo: 'South_Dakota',
		url: 'southdakota',
	},
	{
		value: 'South Texas',
		label: 'South Texas',
		logo: 'South_Texas',
		url: 'southtexas',
	},
	{
		value: 'Southeast Florida',
		label: 'Southeast Florida',
		logo: 'Southeast_Florida',
		url: 'southeastflorida',
	},
	{
		value: 'Southeast Texas',
		label: 'Southeast Texas',
		logo: 'Southeast_Texas',
		url: 'southeasttexas',
	},
	{
		value: 'Southwest Florida',
		label: 'Southwest Florida',
		logo: 'Southwest_Florida',
		url: 'southwestflorida',
	},
	{ value: 'Tampa Bay', label: 'Tampa Bay', logo: 'Tampa_Bay', url: 'tampa' },
	{
		value: 'Tennessee',
		label: 'Tennessee',
		logo: 'Tennessee',
		url: 'tennessee',
	},
	{ value: 'Utah', label: 'Utah', logo: 'Utah', url: 'utah' },
	{ value: 'Vermont', label: 'Vermont', logo: 'Vermont', url: 'vermont' },
	{ value: 'Virginia', label: 'Virginia', logo: 'Virginia', url: 'virginia' },
	{
		value: 'Washington',
		label: 'Washington',
		logo: 'Washington',
		url: 'washington',
	},
	{
		value: 'West Texas',
		label: 'West Texas',
		logo: 'West_Texas',
		url: 'westtexas',
	},
	{
		value: 'West Virginia',
		label: 'West Virginia',
		logo: 'West_Virginia',
		url: 'westvirginia',
	},
	{
		value: 'Western New York',
		label: 'Western New York',
		logo: 'Western_New_York',
		url: 'westernny',
	},
	{
		value: 'Western Pennsylvania',
		label: 'Western Pennsylvania',
		logo: 'Western_Pennsylvania',
		url: 'westernpa',
	},
	{
		value: 'Wisconsin',
		label: 'Wisconsin',
		logo: 'Wisconsin',
		url: 'wisconsin',
	},
	{ value: 'Wyoming', label: 'Wyoming', logo: 'Wyoming', url: 'wyoming' },
]

export default chapters
