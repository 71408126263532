const affiliations = [
	{ value: 'NYC', label: 'NYC' },
	{ value: 'DC', label: 'DC' },
	{ value: 'Chapter', label: 'Chapter' },
	{
		value: 'Out of the Darkness Campus Walk',
		label: 'Out of the Darkness Campus Walk',
	},
	{
		value: 'Out of the Darkness Community Walk',
		label: 'Out of the Darkness Community Walk',
	},
]

export default affiliations
